<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="3">
							<pui-text-field
								:id="`stopstatuscode-stopstatuspa`"
								v-model="model.stopstatuscode"
								:label="$t('form.stopstatuspa.stopstatuscode')"
								required
								toplabel
								maxlength="2"
							></pui-text-field>
						</v-col>
						<v-col cols="5">
							<pui-text-field
								:id="`portauthorityid-stopstatuspa`"
								v-model="model.description"
								:label="$t('form.stopstatuspa.description')"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">
							<pui-text-field
								:id="`colorhex-stopstatuspa`"
								v-model="model.colorhex"
								:label="$t('form.stopstatuspa.colorhex')"
								required
								toplabel
								maxlength="7"
							></pui-text-field>
						</v-col>
						<v-col cols="4">
							<pui-text-field
								:id="`colorhexoutline-stopstatuspa`"
								v-model="model.colorhexoutline"
								:label="$t('form.stopstatuspa.colorhexoutline')"
								required
								toplabel
								maxlength="7"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'stopstatuspa-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'stopstatuspa'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
